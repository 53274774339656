<template>
  <div class="adhocCharges">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <v-form class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <company-select
                @chosen="checkCompanySelected"
                v-model="companySelected"
              ></company-select>
              <v-col cols="6">
                <v-select
                  v-if="isPrepCompany"
                  menu-props="auto"
                  v-model="selectedPrepId"
                  :items="prepCompaniesList"
                  item-text="name"
                  item-value="id"
                  label="Select Prep Company"
                  outlined
                  dense
                  @change="loadGeneratedBillingQuery(companySelected)"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-row>
          <v-col cols="12" class="pa-6">
            <v-textarea auto-grow outlined v-model="loadedQuery" >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card>
      
    </div>
    <v-snackbar v-model="snackbar" :timeout="4000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import CompanySelect from "../components/forms/CompanySelect";
import BillingSnap from "@/services/BillingSnap.js";

export default {
  components: {
    CompanySelect
    
  },
  data() {
    return {
      validForm: true,
      // Title
      title: "Generated Billing Query",
      // Snackbar
      snackbar: false,
      text: "",
      companySelected: {},
      loadedQuery: '',
      
      loadingData: false,
      isPrepCompany: false,
      prepCompaniesList: [],
      selectedPrepId: null,
    };
  },
  methods: {
    loadGeneratedBillingQuery(company){
        this.loadedQuery = ''
         let prepId = false
          if(this.isPrepCompany && this.selectedPrepId){
            prepId = this.selectedPrepId
          }
        BillingSnap.getGeneratedBillingQuery(company.code, prepId).then(response =>{
          this.loadedQuery = response
        })
    },
    checkCompanySelected(company){
       this.companySelected = company;
      this.companyRatesData = null
      if(company.name.toUpperCase() === 'PREPMATE' || company.code.toUpperCase() === 'V08'){
        this.loadingData = true
        this.isPrepCompany = true
        BillingSnap.getPrepCompanies(company.code).then((response) => {
        this.loadingData = false
        this.prepCompaniesList = response;
      })
      .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loadingData = false;
        });
      }else{
        this.isPrepCompany = false
        this.selectedPrepId = null
        this.loadGeneratedBillingQuery(company)
      }
    }
  
  
  },
 
  computed: {
    loading: function () {
      return this.$root.sharedData.state.loading;
    },
  },
};
</script>

<style>
h1 {
  font-size: 150% !important;
}
</style>